<template>
    <div class="col-md-12 mb-20">
        <div class="panel panel-cascade">
            <div class="panel-body ">
                <div class="col-md-12">
                    <div>
                        <div class="mb-30 mt-20">
                            <div class="col-md-12 ">
                                <div class="">
                                    <div class="col-md-12 text-center mb-40">
                                        <h5 class=" control-label">
                                            <span class="selected-temp">Customers</span>
                                        </h5>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-4 ">
                                                <input type="text" v-model="customername" placeholder="Customer name"
                                                    class="form-control form-cascade-control ">
                                            </div>
                                            <div class="col-md-4 ">
                                                <input type="text" v-model="generatedid" placeholder="VSDIGI-ID"
                                                    class="form-control form-cascade-control ">
                                            </div>
                                            <div class="col-md-2">
                                                <button class="btn btn-primary" @click="search()">Search</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row">
                            <div class="col-md-4" v-for="item in users" :key="item.id" :value="item.id">
                                <div class="customer_data">
                                    <input type="checkbox" :value="item.id" :id="item.id" v-model="selecteduser"
                                        @change="selected($event)">
                                    <label :for="item.id" class="ml5px" style="font-size: 15px;">{{ item.name
                                    }} </label> <span v-if="item.generatedid">({{ item.generatedid }})</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5 class="">Selected Customer</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3" v-for="item in selecteduser" :key="item.id">
                                    <div class="customer_data">

                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import axios from 'axios';
import UploadMixin2 from '../../components/utilities/UploadMixin2.vue'
export default {
    mixins: [UploadMixin2],
    data() {
        return {
            users: [],
            customername: '',
            selecteduser: [],
            generatedid: '',
        }
    },
    mounted() {
        this.search();
    },
    computed: {
        ...mapGetters([])
    },
    methods: {
        search() {
            let param = { iscustomer: 1, name: this.customername, generatedid: this.generatedid }
            window.axios.post('api/fetch/userlist', param)
                .then((response) => this.processResponse(response.data))
                .catch((error) => console.log(error))

        },
        processResponse(data) {
            this.users = data.userlist;
        },
        selected(event) {
            console.log("value", event.target.value)
            this.selecteduser.push()
            console.log(this.selecteduser.join())
            this.$store.commit('assignuserids', this.selecteduser.join())
        },
        // uploaded() {
        //     this.sample = this.uploadedimage
        // },
        // cancel() {
        //     this.$store.commit('assignedititem', null)
        //     this.$store.commit('assignviewno', 1)
        // },
        // template() {
        //     this.$router.push({ name: 'whatsapp/temlates' });
        // },
        // getjsondata() {
        //     let value = null
        //     this.edititem.components.find(function (element) {
        //         if (element.componenttypeint == 2) {
        //             value = JSON.stringify(element.componentjson)
        //         }
        //     })
        //     return value?.text
        // },
        // sendTemplate() {
        //     this.$store.commit('assignloadingstatus', 1)
        //     if (this.edititem != null) {
        //         let param = []
        //         if (this.sendtovalue == 1) {
        //             param = {
        //                 templateid: this.edititem.id, templatename: this.edititem.wattsapptemplatename, userids: this.selecteduser.join(),
        //                 language: this.edititem.language, imageurl: this.sample?.fullurl, sendtotype: this.sendtovalue
        //             }
        //         } else {
        //             param = {
        //                 templateid: this.edititem.id, templatename: this.edititem.wattsapptemplatename,
        //                 language: this.edititem.language, imageurl: this.sample?.fullurl, mobilenos: this.mobileno, sendtotype: this.sendtovalue
        //             }
        //         }
        //         console.log("Params here", param)
        //         // axios.post('api/threads/wattsapp/bulk', param)
        //         //     .then((response) => this.processSendResponse(response.data))
        //         //     .catch((error) => console.log(error))
        //     }

        // },
        // processSendResponse(data) {
        //     this.savetemplate = data
        //     this.$store.commit('assignedititem', null)
        //     this.$store.commit('assignviewno', 1)
        //     this.$store.commit('assignloadingstatus', 0)
        // },
        // sendtomobile() {
        //     this.sendtovalue = 2
        //     console.log("Send to value", this.sendtovalue)
        // },
        // sendtocustomer() {
        //     this.sendtovalue = 1
        //     console.log("Send to value", this.sendtovalue)
        // },
        // checkHeader() {
        //     let value = 0
        //     this.edititem.components.find((element) => {
        //         if (element.componenttypeint == 1) {
        //             value = element.componenttypeint
        //         }
        //     })
        //     return value
        // },
    }
}
</script>