<template>
    <div>
        <div class="col-md-12 row" style="border-bottom: 1px solid #475c78;">
            <div class="col-md-6 flex-start-row">
                <div>
                    <h3 class="page-header" style="margin: 0px;padding: 0px;">
                        <i class="fa fa fa-camera-retro animated bounceInDown show-info"></i>
                        Broadcast Manager
                    </h3>
                </div>

            </div>
            <!-- <div class="col-md-6 text-end">
                <button class="btn btn-success mt-10" @click="template()">Select Template</button>
            </div> -->
        </div>
        <div class="panel-body mt-10" v-if="viewno == 2">
            <div class="col-md-12">
                <div>

                    <div class="row">
                        <div class="col-md-12 text-center">
                            <h5 class="control-label mt-20 mb-20"><span class="selected-temp">Selected Template</span></h5>
                        </div>
                        <!-- <div class="col-md-6 text-end">
                            <label class="control-label">Mobile no.</label>
                            <input type="text" class="customer_data" v-model="mobileno" style="margin-left: 10px;">
                        </div> -->
                    </div>

                    <div class="row flex-center-row">
                        <div class="col-md-4">
                            <div class="temp_size">
                                <div v-if="checkHeader() == 1">
                                    <div>
                                        <h5 class="mt-10"><span class="ml-10">Image</span></h5>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="temp_header text-center">
                                            <img :src="this.edititem.components[checkHeader()]['sampleurl']"
                                                style="width: 180px;height: 180px;">
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-12">
                                    <div>
                                        <h5 class="mt-10"><span class="ml-20">Content</span></h5>
                                    </div>
                                    <div class="">
                                        <div :class="checkHeader() == 1 ? 'temp_body' : 'new_temp_body'">
                                            <!-- Body -->
                                            <div class="col-md-12 mb-10 mt-10">
                                                <span style="word-wrap: break-word;">{{ getjsondata() }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="checkHeader() == 1">
                            <div class="temp_size">
                                <div>
                                    <div>
                                        <h5 class="mt-10"><span class="ml-10">Select Image</span></h5>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="temp_header">
                                            <div class=" row">
                                                <div class="col-md-6">
                                                    <img :src="sample?.fullurl" style="height: 120px;">
                                                </div>
                                                <div class="col-md-6 text-end">
                                                    <input type="file" id="attach_file" name="files" style="width: 100%;"
                                                        @change="processFile2($event)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div>
                                        <h5 class="mt-10"><span class="ml-20">Content</span></h5>
                                    </div>
                                    <div class="">
                                        <div :class="checkHeader() == 1 ? 'temp_body' : 'new_temp_body'">
                                            <!-- Body -->
                                            <div class="col-md-12 mb-10 mt-10">
                                                <span style="word-wrap: break-word;">{{ getjsondata() }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div v-if="viewno == 2">
            <div class="pb-10" style="border-radius: 5px;background: #fff">
                <div class="col-md-12">
                    <div class="col-md-12 mt-30">
                        <div class="row text-center">
                            <div class="col-md-6">
                                <div class="col-md-12">
                                    <div>
                                        <button class="btn btn-success mt-10 mb-10" @click="sendtomobile()"
                                            style="color: green;background: #0080004f;">Send to Mobile Number</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="col-md-12">
                                    <div>
                                        <button class="btn new-btn" @click="sendtocustomer()"
                                            style="color: #0078e3;background: #0078e330;">Send to Customer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mb-20" v-if="sendtovalue == 2">
                    <div class="panel panel-cascade">
                        <div class="panel-body ">
                            <div class="col-md-12">
                                <div class="flex-around-row">
                                    <div class="col-md-6 mb-20">
                                        <div class="form-horizontal cascde-forms mt-20">
                                            <div class="row CHK">
                                                <label class="col-lg-3 col-md-3 control-label">Mobile no. </label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small" type="text"
                                                        v-model="mobileno">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-20 text-center">
                                            <div class=" text-center ">
                                                <button class="btn btn-secondary text-white btn-lg"
                                                    :disabled="this.sendtovalue == 0 ? true : false"
                                                    @click="sendTemplate()">Send </button>
                                                <!-- <button class="btn btn-danger text-white btn-lg" @click="cancel()">Cancel
                                                </button> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="panel panel-cascade">
                    <div class="panel-body ">
                        <div class="col-md-12">
                            <div>
                                <div class="mb-30 mt-20">
                                    <div class="col-md-12 ">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <h5 class=" control-label"><span class="selected-temp">Customers</span>
                                                </h5>
                                            </div>
                                            <div class="col-md-4 text-end">
                                                <input type="text" v-model="customername"
                                                    class="form-control form-cascade-control ">
                                            </div>
                                            <div class="col-md-2">
                                                <button class="btn btn-primary" @click="search()">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 row">
                                    <div class="col-md-3" v-for="item in users" :key="item.id" :value="item.id">
                                        <div class="customer_data">
                                            <input type="checkbox" :value="item.id" :id="item.id" v-model="selecteduser"
                                                @change="selected($event)">
                                            <label :for="item.id" class="ml5px" style="font-size: 15px;">{{ item.name
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h5 class="">Selected Customer</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-3" v-for="item in selecteduser" :key="item.id">
                                            <div class="customer_data">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <whats-app-user></whats-app-user>
        </div>
        <!-- <div class="col-md-12 mt-20 text-center">
            <div class=" text-center ">
                <button class="btn btn-secondary text-white btn-lg" :disabled="this.sendtovalue == 0 ? true : false"
                    @click="sendTemplate()">Send </button>
            </div>
        </div> -->
    </div>



    <!-- <div class="col-md-12" style="margin-top:20px;">
        <div class="panel panel-cascade">

            <div class="panel-body ">
                                  
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-horizontal cascde-forms">
                               
                                <div class="form-group CHK">
                                    <label class="col-lg-4 col-md-3 control-label">Message : </label>
                                    <div class="col-lg-8 col-md-9">
                                        <textarea id="txt_message" class="form-control form-cascade-control input-small" rows="2" cols="20"></textarea>
                                        
                                    </div>
                                </div>
                                <div class="form-group CHK">
                                    <label class="col-lg-4 col-md-4 control-label"></label>
                                    <div class="col-lg-4 col-md-2">
                                        <input type="button" value="Send To Selected" class="btn bg-primary text-white btn-lg btnsendsms">
                                     <img src="/Content/loader.gif" style="width:25px;display:none" class="loader">
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 CHK">
                            <div class="form-horizontal cascde-forms">
                                  <div class="form-group">

                                    <div class="col-lg-12 col-md-9">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            
            </div>
        </div>
    </div> -->
</template>
<script>
import { mapGetters } from 'vuex';
// import axios from 'axios';
import UploadMixin2 from '../../components/utilities/UploadMixin2.vue'
import WhatsAppUser from '../../pages/whatsapp/WhatsAppUser.vue'
export default {
    mixins: [UploadMixin2],
    components: { WhatsAppUser },
    data() {
        return {
            users: [],
            customername: '',
            selecteduser: [],
            show: false,
            sample: [],
            savetemplate: [],
            mobileno: '',
            generatedid: '',
            sendtovalue: 0,
        }
    },
    mounted() {
        this.search();
    },
    computed: {
        ...mapGetters(['customers', 'edititem', 'viewno'])
    },
    methods: {
        search() {
            let param = { iscustomer: 1, name: this.customername, generatedid: this.generatedid }
            window.axios.post('api/fetch/userlist', param)
                .then((response) => this.processResponse(response.data))
                .catch((error) => console.log(error))

        },
        processResponse(data) {
            this.users = data.userlist;
        },
        selected(event) {
            console.log("value", event.target.value)
            this.selecteduser.push()
            console.log(this.selecteduser.join())
        },
        uploaded() {
            this.sample = this.uploadedimage
        },
        cancel() {
            this.$store.commit('assignedititem', null)
            this.$store.commit('assignviewno', 1)
        },
        template() {
            this.$router.push({ name: 'whatsapp/temlates' });
        },
        getjsondata() {
            let value = null
            this.edititem.components.find(function (element) {
                if (element.componenttypeint == 2) {
                    value = JSON.stringify(element.componentjson)
                }
            })
            return value?.text
        },
        sendTemplate() {
            this.$store.commit('assignloadingstatus', 1)
            if (this.edititem != null) {
                let param = []
                if (this.sendtovalue == 1) {
                    param = {
                        templateid: this.edititem.id, templatename: this.edititem.wattsapptemplatename, userids: this.selecteduser.join(),
                        language: this.edititem.language, imageurl: this.sample?.fullurl, sendtotype: this.sendtovalue
                    }
                } else {
                    param = {
                        templateid: this.edititem.id, templatename: this.edititem.wattsapptemplatename,
                        language: this.edititem.language, imageurl: this.sample?.fullurl, mobilenos: this.mobileno, sendtotype: this.sendtovalue
                    }
                }
                console.log("Params here", param)
                // axios.post('api/threads/wattsapp/bulk', param)
                //     .then((response) => this.processSendResponse(response.data))
                //     .catch((error) => console.log(error))
            }

        },
        processSendResponse(data) {
            this.savetemplate = data
            this.$store.commit('assignedititem', null)
            this.$store.commit('assignviewno', 1)
            this.$store.commit('assignloadingstatus', 0)
        },
        sendtomobile() {
            this.sendtovalue = 2
            console.log("Send to value", this.sendtovalue)
        },
        sendtocustomer() {
            this.sendtovalue = 1
            console.log("Send to value", this.sendtovalue)
        },
        checkHeader() {
            let value = 0
            this.edititem.components.find((element) => {
                if (element.componenttypeint == 1) {
                    value = element.componenttypeint
                }
            })
            return value
        },
    }
}
</script>
<style></style>